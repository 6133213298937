html.not-top #page,
html.not-top #template-home,
html #template-home{
	
	#logo svg *{
		fill: #ADB0B2 !important;
	}
	
	.verdis{		
		svg{
			*{
				fill: #ADB0B2 !important;
			}
		}
	}
	
}

#page{

	#goals{
		display: none;
	}
}

#template-home, #page{
	
	header{
		position: static;
		padding-bottom: 100px !important;
		
		&:after{
			display: none;
		}
	}
	main{
		margin-top: 0 !important;
	}
	footer{
		#footer-content{
			display: none;
		}
	}
	
}


#top-arrow{
	display: none;
}
#hero{
	padding: 0 !important;
}

.goalbox{
	page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
}